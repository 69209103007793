<template>
  <div>
    <cep
      :can_change_status="can_change_status"
      :cep="cep"
      :quote_file="quote_file"
      :users="users"
      :sceneries="sceneries"
      :clients="clients"
      :cep_statuses="cep_statuses"
      :cep_interlocs="cep_interlocs"
      :cep_contact="cep_contact"
      :cep_bill_info="cep_bill_info"
    ></cep>
    <cep-alerts
      :is_show="true"
      :title="cep.title"
      :description="cep.description"
      :main_kind="cep.main_kind"
      :market_id="cep.market_id"
      :grade="cep.grade"
      :starts_at="cep.starts_at"
      :finishes_at="cep.finishes_at"
      :quoted_at="cep.quoted_at"
      :quote_number="cep.quote_number"
      :quote_expires_at="cep.quote_expires_at"
      :budget_media_kind="cep.budget_media_kind"
      :budget_media_tot_eur="cep.budget_media_tot_eur"
      :calcd_24_prod_media_tot_eur="cep.calcd_24_prod_media_tot_eur"
      :pl_id="cep.pl_id"
      :pc_id="cep.pc_id"
      :prod_team_stip_lines="cep_lines.prod_team_stip_lines"
      :prod_team_piva_lines="cep_lines.prod_team_piva_lines"
      :output_lines="cep_lines.output_lines"
      :pagam_lines="cep_lines.pagam_lines"
      :total_price="cep.calcd_prezzo_vendita_tot_eur"
    ></cep-alerts>
    <div class="tabs-h-wrapper">
      <span :class="active_tab=='ce' ? 'active' : ''" @click="selectTab('ce')">Conto economico</span>
      <span :class="active_tab=='p' ? 'active' : ''" @click="selectTab('p')">Preventivo</span>
      <span :class="active_tab=='l' ? 'active' : ''" @click="selectTab('l')">Link di progetto</span>
    </div>
    <div class="tabs-c-wrapper">
      <div :class="active_tab=='ce' ? 'active-tab' : ''">
        <cep-c-e
          :cep="cep"
          :users="users"
          :cep_lines="cep_lines"
          :item_general_kinds="item_general_kinds"
          :stip_act_types="stip_act_types"
          :prod_others_types="prod_others_types"
          :partners="partners"
          :avg_values="avg_values"
          ref="cep_form_ce"
        ></cep-c-e>
      </div>
      <div :class="active_tab=='p' ? 'active-tab' : ''">
        <cep-p
          :cep="cep"
          :cep_lines="cep_lines"
          :markets="markets"
          :signalmen="signalmen"
          :grades="grades"
          :main_kinds="main_kinds"
          :budget_media_kinds="budget_media_kinds"
        ></cep-p>
      </div>
      <div :class="active_tab=='l' ? 'active-tab' : ''">
        <cep-l
          :cep="cep"
        ></cep-l>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      can_change_status: Boolean,
      cep: Object,
      quote_file: Object,
      users: Array,
      sceneries: Array,
      clients: Array,
      signalmen: Array,
      cep_statuses: Array,
      cep_interlocs: Array,
      cep_lines: Object,
      item_general_kinds: Array,
      stip_act_types: Array,
      prod_others_types: Array,
      partners: Array,
      markets: Array,
      grades: Array,
      main_kinds: Array,
      budget_media_kinds: Array,
      avg_values: Object,
      cep_contact: Object,
      cep_bill_info: Object,
    },

    data(){
      return {
        active_tab: 'ce',
      }
    },

    mounted(){
      this.$forceUpdate();
    },

    methods: {
      selectTab(tab){
        if(this.active_tab!=tab){
          this.active_tab = tab;
        }
      },
    }
  }
</script>
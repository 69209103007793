<template>
  <div>
    <div class="row">
      <div class="col-12">
        <!-- Step 0 form | START -->
        <template v-if="step==0">
          <p class="section-ot">Seleziona uno scenario</p>
          <div class="row">
            <template v-for="scenery in sceneries">
              <div class="col-12 col-md-4">
                <div class="scenery-box-wrapper" :class="cep_data.scenery_id==scenery.id ? 'active' : ''">
                  <div class="h-wrapper">
                    <span class="checkmark" :class="cep_data.scenery_id==scenery.id ? 'active' : ''" @click="selectScenery(scenery.id)"></span>
                    <h4>{{scenery.code}}</h4>
                    <p>{{scenery.desc}}</p>
                  </div>
                  <div class="f-wrapper">
                    <div class="row">
                      <template v-if="scenery.ea_visible">
                        <div class="col-12 col-md-6">
                          <label for="ea_id">EA<template v-if="scenery.must_there_be_ea">*</template></label>
                          <template v-if="cep_data.scenery_id==scenery.id">
                            <CSelect id="ea_id" name="ea_id" placeholder="Seleziona un'opzione" :options="eas" :disabled="false" v-model.number="cep_data.ea_id"></CSelect>
                          </template>
                          <template v-else>
                            <CSelect id="ea_id" name="ea_id" placeholder="Seleziona un'opzione" :options="eas" :disabled="true" v-model.number="d_ea_id"></CSelect>
                          </template>
                        </div>
                      </template>
                      <template v-if="scenery.pl_visible">
                        <div class="col-12 col-md-6">
                          <label for="pl_id">PL<template v-if="scenery.must_there_be_pl">*</template></label>
                          <template v-if="cep_data.scenery_id==scenery.id">
                            <CSelect id="pl_id" name="pl_id" placeholder="Seleziona un'opzione" :options="pls" :disabled="false" v-model.number="cep_data.pl_id"></CSelect>
                          </template>
                          <template v-else>
                            <CSelect id="pl_id" name="pl_id" placeholder="Seleziona un'opzione" :options="pls" :disabled="true" v-model.number="d_pl_id"></CSelect>
                          </template>
                        </div>
                      </template>
                      <template v-if="scenery.pc_visible">
                        <div class="col-12 col-md-6">
                          <label for="pc_id">PC<template v-if="scenery.must_there_be_pc">*</template></label>
                          <template v-if="cep_data.scenery_id==scenery.id">
                            <CSelect id="pc_id" name="pc_id" placeholder="Seleziona un'opzione" :options="pcs" :disabled="false" v-model.number="cep_data.pc_id"></CSelect>
                          </template>
                          <template v-else>
                            <CSelect id="pc_id" name="pc_id" placeholder="Seleziona un'opzione" :options="pcs" :disabled="true" v-model.number="d_pc_id"></CSelect>
                          </template>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <template v-if="step_required_fields_error && step_required_fields_error.length>0">
            <p class="required-fields">Completa tutti i campi obbligatori per procedere</p>
          </template>
          <span class="save-btn" @click="nextStep()">Procedi</span>
        </template>
        <!-- Step 0 form | END -->

        <!-- Step 0 visual | START -->
        <template v-if="step>0">
          <div class="visual-wrapper">
            <p class="section-ot">Seleziona uno scenario</p>
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="scenery-box-wrapper active">
                  <div class="h-wrapper">
                    <span class="checkmark active"></span>
                    <h4>{{$findObjInArray(sceneries, 'id', cep_data.scenery_id).code}}</h4>
                    <p>{{$findObjInArray(sceneries, 'id', cep_data.scenery_id).desc}}</p>
                  </div>
                  <div class="f-wrapper">
                    <div class="row">
                      <template v-if="$findObjInArray(sceneries, 'id', cep_data.scenery_id).ea_visible">
                        <div class="col-12 col-md-6">
                          <label for="ea_id">EA<template v-if="$findObjInArray(sceneries, 'id', cep_data.scenery_id).must_there_be_ea">*</template></label>
                          <CSelect id="ea_id" name="ea_id" placeholder="Seleziona un'opzione" :options="eas" :disabled="false" v-model.number="cep_data.ea_id"></CSelect>
                        </div>
                      </template>
                      <template v-if="$findObjInArray(sceneries, 'id', cep_data.scenery_id).pl_visible">
                        <div class="col-12 col-md-6">
                          <label for="pl_id">PL<template v-if="$findObjInArray(sceneries, 'id', cep_data.scenery_id).must_there_be_pl">*</template></label>
                          <CSelect id="pl_id" name="pl_id" placeholder="Seleziona un'opzione" :options="pls" :disabled="false" v-model.number="cep_data.pl_id"></CSelect>
                        </div>
                      </template>
                      <template v-if="$findObjInArray(sceneries, 'id', cep_data.scenery_id).pc_visible">
                        <div class="col-12 col-md-6">
                          <label for="pc_id">PC<template v-if="$findObjInArray(sceneries, 'id', cep_data.scenery_id).must_there_be_pc">*</template></label>
                          <CSelect id="pc_id" name="pc_id" placeholder="Seleziona un'opzione" :options="pcs" :disabled="false" v-model.number="cep_data.pc_id"></CSelect>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- Step 0 visual | END -->

        <!-- Step 1 form | START -->
        <template v-if="step==1">
          <div class="form-box-wrapper azure expanded">
            <div class="heading">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><strong>1.</strong> Cliente</p>
                </div>
              </div>
            </div>
            <hr>
            <div class="value-row">
              <div class="row">
                <div class="col-12">
                  <label class="radio-wrapper">
                    Seleziona cliente
                    <input type="radio" name="creating_client" :value="false" v-model="creating_client">
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-wrapper" @click="newClientSelected()">
                    Crea nuovo cliente
                    <input type="radio" name="creating_client" :value="true" v-model="creating_client">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="value-row izi">
              <template v-if="!creating_client">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <div class="row">
                      <div class="col-12 col-md-8">
                        <label for="client_id">Seleziona cliente*</label>
                        <CAutocomplete id="client_id" name="client_id" placeholder="Cerca e seleziona un cliente" :list="ac_clients" :search_fields="['name','code']" value_field="id" view_field="visual_value" v-model.number="cep_data.client_id"></CAutocomplete>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-12 col-md-8">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <label for="name">Nome cliente*</label>
                        <CInput id="name" name="name" placeholder="..." :readonly="false" v-model="client_data.name"></CInput>
                      </div>
                      <div class="col-12 col-md-4">
                        <label for="code">Codice*</label>
                        <CInput id="code" name="code" placeholder="..." :readonly="false" v-model="client_data.code"></CInput>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <template v-if="cep_data.client_id || creating_client">
              <div class="value-row">
                <div class="row">
                  <div class="col-12">
                    <label class="radio-wrapper">
                      Seleziona account di fatturazione
                      <input type="radio" name="creating_bill_info" :value="false" v-model="creating_bill_info">
                      <span class="checkmark"></span>
                    </label>
                    <label class="radio-wrapper">
                      Crea nuovo account di fatturazione
                      <input type="radio" name="creating_bill_info" :value="true" v-model="creating_bill_info">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="value-row">
                <template v-if="!creating_bill_info">
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <div class="row">
                        <div class="col-12 col-md-8">
                          <label for="bill_info_id">Seleziona account di fatturazione*</label>
                          <CAutocomplete id="bill_info_id" name="bill_info_id" placeholder="Cerca e seleziona un account di fatturazione" :list="ac_bill_infos" :search_fields="['business_name','vat_code']" value_field="id" view_field="visual_value" v-model.number="cep_data.bill_info_id"></CAutocomplete>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <label for="business_name">Denominazione azienda*</label>
                          <CInput id="business_name" name="business_name" placeholder="..." :readonly="false" v-model="bill_info_data.business_name"></CInput>
                        </div>
                        <div class="col-12 col-md-4">
                          <label for="vat_code">P.Iva</label>
                          <CInput id="vat_code" name="vat_code" placeholder="..." :readonly="false" v-model="bill_info_data.vat_code"></CInput>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <div class="row">
                        <div class="col-12 col-md-4">
                          <label for="address">Indirizzo</label>
                          <CInput id="address" name="address" placeholder="..." :readonly="false" v-model="bill_info_data.address"></CInput>
                        </div>
                        <div class="col-12 col-md-4">
                          <label for="sdi">SDI</label>
                          <CInput id="sdi" name="sdi" placeholder="..." :readonly="false" v-model="bill_info_data.sdi"></CInput>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <template v-if="step_required_fields_error && step_required_fields_error.length>0">
            <p class="required-fields">Completa tutti i campi obbligatori per procedere</p>
          </template>
          <template v-if="existing_client_code_error">
            <p class="required-fields">Il codice cliente inserito è già presente</p>
          </template>
          <span class="save-btn" @click="nextStep()">Procedi</span>
        </template>
        <!-- Step 1 form | END -->

        <!-- Step 1 visual | START -->
        <template v-if="step>1">
          <div class="visual-wrapper">
            <div class="form-box-wrapper azure">
              <div class="heading">
                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <p><strong>1.</strong> Cliente</p>
                  </div>
                  <div class="col-12 col-md-4">
                    <template v-if="!creating_client">
                      <span class="value">{{$findObjInArray(clients, 'id', cep_data.client_id).code}} - {{$findObjInArray(clients, 'id', cep_data.client_id).name}}</span>
                    </template>
                    <template v-else>
                      <span class="value">{{client_data.code}} - {{client_data.name}}</span>
                    </template>
                    <br><br>
                    <template v-if="!creating_bill_info">
                      <span class="value">{{$findObjInArray(bill_infos, 'id', cep_data.bill_info_id).business_name}} - {{$findObjInArray(bill_infos, 'id', cep_data.bill_info_id).vat_code}}</span>
                    </template>
                    <template v-else>
                      <span class="value">{{bill_info_data.business_name}} - {{bill_info_data.vat_code}}</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- Step 1 visual | END -->

        <!-- Step 2 form | START -->
        <template v-if="step==2">
          <div class="form-box-wrapper azure expanded">
            <div class="heading">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><strong>2.</strong> Interlocutore</p>
                </div>
              </div>
            </div>
            <hr>
            <div class="value-row">
              <div class="row">
                <div class="col-12">
                  <label class="radio-wrapper">
                    Seleziona interlocutore
                    <input type="radio" name="creating_contact" :value="false" v-model="creating_contact">
                    <span class="checkmark"></span>
                  </label>
                  <label class="radio-wrapper">
                    Crea nuovo interlocutore
                    <input type="radio" name="creating_contact" :value="true" v-model="creating_contact">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="value-row">
              <template v-if="!creating_contact">
                <div class="row">
                  <div class="col-12 col-md-8">
                    <div class="row">
                      <div class="col-12 col-md-8">
                        <label for="contact_id">Seleziona interlocutore*</label>
                        <CAutocomplete id="contact_id" name="contact_id" placeholder="Cerca e seleziona un interlocutore" :list="ac_contacts" :search_fields="['name','email','telephone']" value_field="id" view_field="visual_value" v-model.number="cep_data.contact_id"></CAutocomplete>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-12 col-md-8">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <label for="name">Nome interlocutore*</label>
                        <CInput id="name" name="name" placeholder="..." :readonly="false" v-model="contact_data.name"></CInput>
                      </div>
                      <div class="col-12 col-md-4">
                        <label for="email">E-mail*</label>
                        <CInput id="email" name="email" placeholder="..." :readonly="false" v-model="contact_data.email"></CInput>
                      </div>
                      <div class="col-12 col-md-4">
                        <label for="telephone">Telefono</label>
                        <CInput id="telephone" name="telephone" placeholder="..." :readonly="false" v-model="contact_data.telephone"></CInput>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <template v-if="step_required_fields_error && step_required_fields_error.length>0">
            <p class="required-fields">Completa tutti i campi obbligatori per procedere</p>
          </template>
          <span class="save-btn" @click="nextStep()">Procedi</span>
        </template>
        <!-- Step 2 form | END -->

        <!-- Step 2 visual | START -->
        <template v-if="step>2">
          <div class="visual-wrapper">
            <div class="form-box-wrapper azure">
              <div class="heading">
                <div class="row align-items-center">
                  <div class="col-12 col-md-8">
                    <p><strong>2.</strong> Interlocutore</p>
                  </div>
                  <div class="col-12 col-md-4">
                    <template v-if="!creating_contact">
                      <span class="value">{{$findObjInArray(contacts, 'id', cep_data.contact_id).name}}</span>
                    </template>
                    <template v-else>
                      <span class="value">{{contact_data.name}}</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- Step 2 visual | END -->

        <!-- Step 3 form | START -->
        <template v-if="step==3">
          <div class="form-box-wrapper azure expanded">
            <div class="heading">
              <div class="row align-items-center">
                <div class="col-12 col-md-8">
                  <p><strong>3.</strong> Informazioni progetto</p>
                </div>
              </div>
            </div>
            <hr>
            <div class="value-row">
              <div class="row">
                <div class="col-12 col-md-8">
                  <div class="row">
                    <div class="col-12 col-md-4" :class="wrong_project_code_format || existing_project_code_format || regex_project_code_format ? 'is-invalid' : ''">
                      <label for="project_code">Cod. Progetto*</label>
                      <CInput id="project_code" name="project_code" placeholder="COD." :readonly="false" v-model="cep_data.project_code"></CInput>
                    </div>
                    <div class="col-12 col-md-4">
                      <label for="interloc">Livello interlocutore*</label>
                      <CSelect id="interloc" name="interloc" placeholder="Seleziona un'opzione" :options="cep_interlocs" :disabled="false" v-model.number="cep_data.interloc"></CSelect>
                    </div>
                    <div class="col-12 col-md-4">
                      <label for="informal_title">Titolo progetto*</label>
                      <CInput id="informal_title" name="informal_title" placeholder="..." :readonly="false" v-model="cep_data.informal_title"></CInput>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="checkbox-wrapper">
                        Desideri creare la cartella su Drive?
                        <input type="checkbox" name="create_drive_folder" v-model="cep_data.create_drive_folder">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="step_required_fields_error && step_required_fields_error.length>0">
            <p class="required-fields">Completa tutti i campi obbligatori per procedere</p>
          </template>
          <template v-if="save_error">
            <p class="required-fields">Si è verificato un errore</p>
          </template>
          <template v-if="wrong_project_code_format">
            <p class="required-fields">Il codice progetto deve iniziare con "<strong><template v-if="!creating_client">{{$findObjInArray(clients, 'id', cep_data.client_id).code}}</template><template v-else>{{client_data.code}}</template>.</strong>"</p>
          </template>
          <template v-if="existing_project_code_format">
            <p class="required-fields">Il codice progetto inserito è già stato utilizzato</p>
          </template>
          <template v-if="regex_project_code_format">
            <p class="required-fields">Il codice progetto inserito non è formattato correttamente</p>
          </template>
          <span class="save-btn" :class="btn_class" @click="saveCep()">Crea CEP</span>
        </template>
        <!-- Step 3 form | END -->
      </div>
    </div>
  </div>
</template>

<script>
  import CInput from "../form/CInput.vue"
  import CSelect from "../form/CSelect.vue"
  import CAutocomplete from "../form/CAutocomplete.vue"

  import * as helperFunctions from "../../helpers/helpers"

  export default {
    components:{
      CInput,
      CSelect,
      CAutocomplete,
    },

    props: {
      rails_env: String,
      cep: Object,
      users: Array,
      sceneries: Array,
      clients: Array,
      contacts: Array,
      bill_infos: Array,
      cep_statuses: Array,
      cep_interlocs: Array,
      project_codes: Array,
    },

    data(){
      return {
        step: 0,

        d_ea_id: null,
        d_pl_id: null,
        d_pc_id: null,

        cep_data: {
          create_drive_folder: false,
          scenery_id: this.sceneries && this.sceneries.length>0 ? this.sceneries[0].id : null,
          ea_id: null,
          pl_id: null,
          pc_id: null,
          client_id: null,
          contact_id: null,
          project_code: null,
          status: 0,
          interloc: null,
          informal_title: null,
          bill_info_id: null,
        },

        creating_client: false,

        client_data: {
          name: null,
          code: null,
          ht_id: null,
        },

        creating_bill_info: false,

        bill_info_data: {
          business_name: null,
          vat_code: null,
          address: null,
          sdi: null,
          client_id: null,
        },

        creating_contact: false,

        contact_data: {
          name: null,
          email: null,
          telephone: null,
          client_id: null,
        },

        step_required_fields_error: [],

        existing_client_code_error: false,

        wrong_project_code_format: false,
        existing_project_code_format: false,
        regex_project_code_format: false,

        save_error: false,

        btn_class: '',
      }
    },

    computed: {
      eas: function(){
        return this.users.filter((item) => { return item.is_ea; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pls: function(){
        return this.users.filter((item) => { return item.is_pl; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      pcs: function(){
        return this.users.filter((item) => { return item.is_pc; }).map((item) => { return {label: this.$niceUserFullName(item), value: item.id, localized_label: this.$niceUserFullName(item)}; });
      },
      ac_contacts: function(){
        return this.contacts.filter((item) => { return item.client_id==this.cep_data.client_id; }).map((item) => { return {id: item.id, name: item.name, email: item.email, telephone: item.telephone, visual_value: item.name+' 📞 '+this.$niceField(item.telephone)+' 📧 '+this.$niceField(item.email)}; });
      },
      ac_clients: function(){
        return this.clients.map((item) => { return {id: item.id, code: item.code, name: item.name, visual_value: item.code+' | '+item.name}; });
      },
      ac_bill_infos: function(){
        return this.bill_infos.filter((item) => { return item.client_id==this.cep_data.client_id; }).map((item) => { return {id: item.id, business_name: item.business_name, vat_code: item.vat_code, visual_value: item.business_name+' | '+item.vat_code}; });
      },
      client_codes: function(){
        return this.clients.map((item) => { return item.code; });
      },
    },

    methods: {
      ...helperFunctions,

      selectScenery(scenery_id){
        this.cep_data.scenery_id = scenery_id;
      },

      newClientSelected(){
        this.creating_bill_info = true;
        this.creating_contact = true;
      },

      getRequiredFields(){
        var required_fields = [];
        switch(this.step){
          case 0:
            required_fields = ['scenery_id'];
            var scenery = this.sceneries.find(x => x.id==this.cep_data.scenery_id);
            if(scenery.must_there_be_ea){
              required_fields.push('ea_id');
            }
            if(scenery.must_there_be_pl){
              required_fields.push('pl_id');
            }
            if(scenery.must_there_be_pc){
              required_fields.push('pc_id');
            }
            break;
          case 1:
            required_fields = ['client_id', 'bill_info_id'];
            break;
          case 2:
            required_fields = ['contact_id'];
            break;
          case 3:
            required_fields = ['project_code', 'status', 'interloc', 'informal_title'];
            break;
          default:break;
        }
        return required_fields;
      },

      allRequiredFieldsAreComplete(){
        var flag = true;
        var step_required_fields_error = [];
        this.getRequiredFields().forEach((obj_key) => {
          switch(obj_key){
            case 'client_id':
              if(this.creating_client){
                if(!this.client_data.name || !this.client_data.code){
                  flag = false;
                  step_required_fields_error.push('client_name');
                  step_required_fields_error.push('client_code');
                }
                if(this.client_codes.includes(this.client_data.code)){
                  flag = false;
                  this.existing_client_code_error = true;
                }
                else{
                  this.existing_client_code_error = false;
                }
              }
              else{
                if(!this.cep_data[obj_key] || this.cep_data[obj_key]==''){
                  flag = false;
                  step_required_fields_error.push(obj_key);
                }
              }
              break;
            case 'bill_info_id':
              if(this.creating_bill_info){
                if(!this.bill_info_data.business_name){
                  flag = false;
                  step_required_fields_error.push('bill_info_business_name');
                }
              }
              else{
                if(!this.cep_data[obj_key] || this.cep_data[obj_key]==''){
                  flag = false;
                  step_required_fields_error.push(obj_key);
                }
              }
              break;
            case 'contact_id':
              if(this.creating_contact){
                if(!this.contact_data.name || !this.contact_data.email){
                // if(!this.contact_data.name || !this.contact_data.email || !this.contact_data.telephone){
                  flag = false;
                  step_required_fields_error.push('contact_name');
                  step_required_fields_error.push('contact_email');
                  // step_required_fields_error.push('contact_telephone');
                }
              }
              else{
                if(!this.cep_data[obj_key] || this.cep_data[obj_key]==''){
                  flag = false;
                  step_required_fields_error.push(obj_key);
                }
              }
              break;
            case 'status':
            case 'interloc':
              if(this.cep_data[obj_key]===null){
                flag = false;
                step_required_fields_error.push(obj_key);
              }
              break;
            case 'project_code':
              if(!this.cep_data[obj_key] || this.cep_data[obj_key]==''){
                flag = false;
                step_required_fields_error.push(obj_key);
              }
              else{
                var client_code = null;
                if(!this.creating_client){
                  client_code = this.$findObjInArray(this.clients, 'id', this.cep_data.client_id).code + '.';
                }
                else{
                  client_code = this.client_data.code + '.';
                }
                if(!this.cep_data[obj_key].startsWith(client_code)){
                  flag = false;
                  this.wrong_project_code_format = true;
                }
                else{
                  this.wrong_project_code_format = false;
                }
                if(this.project_codes.includes(this.cep_data[obj_key])){
                  flag = false;
                  this.existing_project_code_format = true;
                }
                else{
                  this.existing_project_code_format = false;
                }
                var regex = /^(.*){3,}\.(.*){3,}\.\d{3,}$/;
                if(!regex.test(this.cep_data[obj_key])){
                  flag = false;
                  this.regex_project_code_format = true;
                }
                else{
                  this.regex_project_code_format = false;
                }
              }
              break;
            default:
              if(!this.cep_data[obj_key] || this.cep_data[obj_key]==''){
                flag = false;
                step_required_fields_error.push(obj_key);
              }
              break;
          }
        });
        this.step_required_fields_error = step_required_fields_error;
        return flag;
      },

      nextStep(){
        if(this.allRequiredFieldsAreComplete()){
          this.step++;
          if(this.step==2){
            if(!this.creating_client){
              this.cep_data.project_code = this.$findObjInArray(this.clients, 'id', this.cep_data.client_id).code + '.';
            }
            else{
              this.cep_data.project_code = this.client_data.code + '.';
            }
          }
        }
      },

      saveCep(){
        if(this.allRequiredFieldsAreComplete()){
          this.btn_class = 'loading';
          this.save_error = false;
          this.saveClientData().then(response => {
            var obj = response.data;
            if(obj && obj.status=='Ok'){
              this.saveBillInfoData().then(response => {
                var obj = response.data;
                if(obj && obj.status=='Ok'){
                  this.saveContactData().then(response => {
                    var obj = response.data;
                    if(obj && obj.status=='Ok'){
                      this.saveCepData().then(response => {
                        var obj = response.data;
                        if(obj && obj.status=='Ok' && obj.cep_id){
                          window.location.href = '/ceps/'+obj.cep_id+'/edit';
                        }
                        else{
                          this.btn_class = '';
                          this.save_error = true;
                        }
                      });
                    }
                    else{
                      this.btn_class = '';
                      this.save_error = true;
                    }
                  });
                }
                else{
                  this.btn_class = '';
                  this.save_error = true;
                }
              });
            }
            else{
              this.btn_class = '';
              this.save_error = true;
            }
          });
        }
      },

      saveClientData(){
        var inst = this;
        return new Promise(resolve => {
          if(inst.creating_client){
            if(inst.rails_env == 'production'){
              inst.saveHTClientData().then(response => {
                var obj = response.data;
                if(obj){
                  inst.saveSalesClientData().then(response => {
                    resolve(response);
                  });
                }
                else{
                  resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
                }
              });
            }
            else{
              inst.saveSalesClientData().then(response => {
                resolve(response);
              });
            }
          }
          else{
            resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
          }
        });
      },

      saveHTClientData(){
        var inst = this;
        return new Promise(resolve => {
          var params = inst.client_data;
          params.token = 'eBXRhmMb46gkqpEHUCQdfFWzyvANwu3GncraDK8PZS572T9LV';
          inst.axios.post('https://ht.twig.agency/api/v24/clients/', params).then(response => {
            var obj = response.data;
            if(obj && obj.id){
              inst.client_data.ht_id = obj.id;
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      },

      saveSalesClientData(){
        var inst = this;
        return new Promise(resolve => {
          var params = {
            client: inst.client_data,
          };
          inst.axios.post('/api/clients', params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              inst.cep_data.client_id = obj.data.client_id;
              resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      },

      saveBillInfoData(){
        var inst = this;
        return new Promise(resolve => {
          if(inst.creating_bill_info){
            inst.bill_info_data.client_id = inst.cep_data.client_id;
            var params = {
              bill_info: inst.bill_info_data,
            };
            inst.axios.post('/api/bill_infos', params).then(response => {
              var obj = response.data;
              if(obj.data.success){
                inst.cep_data.bill_info_id = obj.data.bill_info_id;
                resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
              }
              else{
                resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
              }
            });
          }
          else{
            resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
          }
        });
      },

      saveContactData(){
        var inst = this;
        return new Promise(resolve => {
          if(inst.creating_contact){
            inst.contact_data.client_id = inst.cep_data.client_id;
            var params = {
              contact: inst.contact_data,
            };
            inst.axios.post('/api/contacts', params).then(response => {
              var obj = response.data;
              if(obj.data.success){
                inst.cep_data.contact_id = obj.data.contact_id;
                resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
              }
              else{
                resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
              }
            });
          }
          else{
            resolve({data: {status: 'Ok', message: 'L\'operazione è stata eseguita con successo'}});
          }
        });
      },

      saveCepData(){
        var inst = this;
        return new Promise(resolve => {
          var params = {
            cep: inst.cep_data,
          };
          inst.axios.post('/api/ceps', params).then(response => {
            var obj = response.data;
            if(obj.data.success){
              resolve({data: {status: 'Ok', cep_id: obj.data.cep_id, message: 'L\'operazione è stata eseguita con successo'}});
            }
            else{
              resolve({data: {status: 'Error', message: 'Si è verificato un errore'}});
            }
          });
        });
      },
    }
  }
</script>

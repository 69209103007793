export function $niceField(field){
  var string = '-';
  if(field){
    string = field;
  }
  return string;
}
export function $niceDate(field){
  var string = '-';
  if(field){
    var date_obj = new Date(field);
    string = ('0' + date_obj.getDate()).slice(-2) + '-' + ('0' + (date_obj.getMonth()+1)).slice(-2) + '-' + date_obj.getFullYear();
  }
  return string;
}
export function $niceFromattedDate(field){
  var string = '-';
  if(field){
    var date_obj = new Date(field);
    string = date_obj.getFullYear() + '-' + ('0' + (date_obj.getMonth()+1)).slice(-2) + '-' + ('0' + date_obj.getDate()).slice(-2);
  }
  return string;
}
export function $niceUserFullName(user){
  return user.first_name+' '+user.last_name;
}
export function $nicePerc(field){
  if(field===null || field===undefined){
    field = 0;
  }
  var string = parseFloat(field).toLocaleString('it-IT', {maximumFractionDigits: 1});
  return string+'%';
}
export function $niceIntPerc(field){
  if(field===null || field===undefined){
    field = 0;
  }
  var string = Math.round(field);
  return string+'%';
}
export function $niceMoney(field){
  if(field===null || field===undefined){
    field = 0;
  }
  var string = '-';
  string = parseFloat((field/100.0)).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  return string+' €';
}
export function $niceMoneyWithMilli(field){
  if(field===null || field===undefined){
    field = 0;
  }
  var string = '-';
  string = parseFloat((field/100.0)).toLocaleString('it-IT', {minimumFractionDigits: 3, maximumFractionDigits: 3});
  return string+' €';
}
export function $niceNumber(field){
  if(field===null || field===undefined){
    field = 0;
  }
  var string = '-';
  string = parseFloat(field).toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  return string;
}
export function $roundOneDecimal(field){
  if(field===null || field===undefined){
    field = 0;
  }
  return parseInt(field*10.0)/10.0;
}
export function $fromCentToEur(field){
  if(field===null || field===undefined){
    field = 0;
  }
  return field/100.0;
}
export function $fromEurToCent(field){
  if(field===null || field===undefined){
    field = 0;
  }
  return parseInt(field*100.0);
}
export function $fromEurToMilli(field){
  if(field===null || field===undefined){
    field = 0;
  }
  return parseInt(field*1000.0)/10.0;
}
export function $fromEurToDec(field){
  if(field===null || field===undefined){
    field = 0;
  }
  return parseInt(field*100.0);
}
export function $roundIntNumber(field){
  if(field===null || field===undefined){
    field = 0;
  }
  return parseInt(Math.round(field*100.0)/100.0);
}
export function $roundFloatNumber(field){
  if(field===null || field===undefined){
    field = 0;
  }
  return Math.round(field*100.0)/100.0;
}
export function $calculatePerc(field){
  if(field===null || field===undefined){
    field = 0;
  }
  return $roundFloatNumber(field*100.0);
}
export function $stringToFloat(field){
  if(field===null || field===undefined){
    field = '0';
  }
  if(typeof field==='string' || field instanceof String){
    field = parseFloat(field.replaceAll(',', '.'));
  }
  return field;
}
export function $getEnumValue(list, label_to_find){
  var value = null;
  var found_obj = list.filter(obj => {
    return obj.label===label_to_find || obj.localized_label===label_to_find;
  });
  if(found_obj && found_obj.length>0){
    found_obj = found_obj[0];
    value = found_obj.value;
  }
  return value;
}
export function $getEnumLabel(list, value_to_find){
  var label = '-';
  var found_obj = list.filter(obj => {
    return obj.value===value_to_find || obj.label===value_to_find;
  });
  if(found_obj && found_obj.length>0){
    found_obj = found_obj[0];
    label = found_obj.localized_label;
  }
  return label;
}
export function $niceObjField(obj, field, extra_field=null){
  var string = '-';
  if(obj && field){
    if(extra_field && obj[field] && obj[extra_field]){
      string = obj[field] + ' ' + obj[extra_field];
    }
    else if(obj[field]){
      string = obj[field];
    }
  }
  return string;
}
export function $findObjInArray(array, key, value){
  return array.find(x => x[key]==value);
}
export function $getRequiredFieldClass(value){
  var class_name = 'is-invalid';
  if(value!=='' && value!==null){
    class_name = '';
  }
  return class_name;
}
